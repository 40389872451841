<template>
  <base-section
    id="phone"
    style="background-color: #91D9E5"
    class="py-8"
  >
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 8"
          class="d-flex align-center justify-center flex-column"
        >
          <div style="position: relative; width: 90vw; max-width: 1024px;">
            <iframe
              id="story"
              style=""
              src="/story.html"
              width="24.5%"
              height="75%"
            />
            <v-img
              :src="require($vuetify.breakpoint.smAndDown ? '@/assets/imgs/phone-mobile.svg' : '@/assets/imgs/phone.svg')"
              class="mx-3"
            />
          </div>
          <base-section-heading
            :title="$t('title')"
            :text="$t('text')"
            class="mt-7"
            color="white"
            dark
            :space="0"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>
<script>
  export default {}
</script>
<style scoped>
#story {
  position: absolute;
  left: 20.1%;
  top: 7%;
}

@supports (-moz-appearance:none) {
  #story {
    left: 0;
    width: 38.5%;
  }
}

@media screen and (max-width: 959px) and (min-width: 601px) {
  #story {
    left: 3.6%;
    width: 41%;
  }
}

@media screen and (max-width: 600px) {
  #story {
    left: 5.25%;
    width: 40%;
  }
}

</style>
<i18n>
{
  "en": {
    "title": "Discover more"
  },
  "it": {
    "title": "Sfrutta le potenzialità delle stories",
    "text": "Per ogni servizio potrai scegliere foto e testi personalizzati, che saranno mostrati come stories d'impatto e accattivanti: per un messaggio mirato e d'effetto"
  }
}
</i18n>
